import { color } from "chart.js/helpers";
import React, { useState } from "react";
import { Form, Header, FormInput } from "semantic-ui-react";

export default function ResumeUpload({ loading, onChangeResume }) {
  console.log("Resume Upload", loading);
  return (
    <React.Fragment>
      <Form loading={loading}>
        <Form.Field>
          <Header as="h3" style={{ textDecoration: "underline", color:"#645B5B", fontWeight: '500' }}>
            Upload docs & pdfs only. Make sure the file size is not more than
            1MB.
          </Header>
          <Header as='h4' style={{color: '#645B5B', fontWeight: '400'}}>Resume</Header>
          <div id="yourBtn">
            <h2 style={{ color: "darkgrey" }}>Choose or Drag file</h2>
            <input
              style={{
                height: "150px",
                marginTop: "-44px",
                paddingTop: "45px",
                paddingLeft: "40%",
                border: "none",
                background: "transparent",
              }}
              type="file"
              accept=".doc, .docx, .pdf"
              onChange={onChangeResume}
              name="document"
              id="document"
            />
          </div>
        </Form.Field>
      </Form>
    </React.Fragment>
  );
}
