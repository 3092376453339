import React from 'react';
import { useEffect } from 'react';
import {
    TransitionablePortal,
    Message
  } from 'semantic-ui-react'
const DisplayModalPortal = ({
    open,
    onClose,
    isSuccess,
    message,
    customMessage
}) => {
    console.log('portal', open)

    useEffect(() => {
      let timer;
  
      if (open) {
        timer = setTimeout(() => {
          onClose();
        }, 1000);
      }
  
      return () => {
        clearTimeout(timer);
      };
    }, [open, onClose]);

    return (
        <TransitionablePortal
            open={open}
            animation = 'down'
            duration ={500}
          >
              <Message style={{
                right: '20px',
                position: 'fixed',
                top: '10%',
                zIndex: 1000,
                height: '100px',
                width: '300px'
              }}
              positive= {isSuccess ? true : false}
              error= {!isSuccess ? true : false}
              onDismiss={onClose}>
                <Message.Header>
                  { isSuccess ? 'Successful' : 'Error'}
                </Message.Header>
                    <p>
                        {message}
                    </p>

                    <p>
                      {customMessage} 
                    </p>
                </Message>  
          </TransitionablePortal>
    )
}

export default React.memo(DisplayModalPortal);