import { Button, Icon, Input, Header, Loader, Grid } from 'semantic-ui-react';
import Validator, {isStrongPassword} from 'validator';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signup } from '../../../../actions/action_auth';
import './custom.css'

const Register = ({ email, setStep, setEmail }) => {
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        client_name: "",
        email: email,
        password: "",
      });
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [linkSent, setLinkSent] = useState(false)

    const handleDataChange = (e) => {
        const {value , name} = e.target

        setData(prevData => ({
            ...prevData,
            [name] : value
        }))

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[name];
            return newErrors;
        });

    }

    const validatePassword = () => {
        const validationErrors = {};

        if (
            !isStrongPassword(data.password, [
                { minLength: 8, minUppercase: 1, minNumbers: 1, minSymbols: 1 },
            ])
        ) {
            validationErrors.password =
                "At least 8 characters required, 1 Uppercase, 1 Number, and 1 Symbol required";
        }
    
        if (!data.password) {
            validationErrors.password = "Password is required!";
        }
    
        return validationErrors;
    };


    const onSubmit = async () => {
        const passwordErrors = validatePassword();
    
        // Merge password validation errors with existing errors
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...passwordErrors,
        }));
    
        // If there are password errors, don't proceed with the API call
        if (Object.keys(passwordErrors).length > 0) {
            return;
        }
    
        setMessage(null);
        setLoading(true);
    
        try {
            // Dispatch signup action
            const res = await dispatch(signup(data));
            console.log("Response from sign up:", res);
    
            if (res?.data?.success === true) {
                setLinkSent(true);
    
                setMessage({
                    type: "success",
                    text: "Sign up successful! Please check your email for an activation link valid for 5 minutes.",
                });
            } else {
                setMessage({
                    type: "error",
                    text: "An unexpected issue occurred during sign up.",
                });
            }
    
            setLoading(false);
        } catch (err) {
            console.log("Error in sign up", err.response?.data);
    
            setLoading(false);
    
            // Handle specific status code errors
            setErrors((prevErrors) => {
                const fieldErrors = { ...prevErrors };
    
                if (err.response?.data?.errors) {
                    // Merge new field errors with existing errors
                    err.response.data.errors.forEach((error) => {
                        fieldErrors[error.path] = error.msg;
                    });
                }
    
                if (err.response?.status === 409) {
                    // Add conflict (409) error
                    fieldErrors.conflict = err.response.data.message;
                }
    
                if (err.response?.status === 422) {
                    // Add validation (422) error
                    fieldErrors.validation = err.response.data.error;
                }
    
                return fieldErrors;
            });
    
            // Handle generic error message
            if (!err.response?.data?.errors && !err.response?.data?.message) {
                setMessage({
                    type: "error",
                    text: "Some error occurred while signing up.",
                });
            }
        }
    };
    
    
    
    const openGmail = () => {
        window.open('https://mail.google.com', '_blank');
    }

    console.log(linkSent, 'checking register data', message)
    

    return (
        <div textAlign="center">
            {linkSent ? (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '1rem' }}>
                        <Icon
                            name="arrow left"
                            onClick={() => {
                                setStep(0);
                                setEmail('')
                            }}
                            style={{ cursor: 'pointer', color: '#3D3737' }}
                        />
                        <Header as="h2" style={{ color: '#3D3737', fontWeight: 'bold', marginTop: "0" }}>You’re almost signed up</Header>
                    </div>
                    <p style={{ margin: '0.5rem 0 1.5rem', color: '#3D3737', textAlign: 'center', fontSize: '1rem' }}>
                        Click the verification link on your email to confirm your account.
                    </p>     

                    <Button
                        style={{
                            width: '100%',
                            background: "#21C8AA",
                            color: 'white',
                            margin: '1rem 0 0',
                            padding: '1rem 0',
                            textTransform: 'none',
                            borderRadius: '8px',
                            fontWeight: '600',
                            fontSize: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={openGmail}
                    >
                        VERIFY & GET STARTED
                    </Button>      
                </>
            ) : (
               <>
               
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '1rem' }}>
                    <Icon
                        name="arrow left"
                        onClick={() => {
                            setStep(0);
                            setEmail('')
                        }}
                        style={{ cursor: 'pointer', color: '#3D3737' }}
                    />
                    <Header as="h2" style={{ color: '#3D3737', fontWeight: 'bold', marginTop: "0" }}>Create your account</Header>
                </div>
                <p style={{ margin: '0.5rem 0 1.5rem', color: '#3D3737', textAlign: 'center', fontSize: '1rem' }}>
                    Creating a Qrata account with email: {email}
                </p>

                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={8} style={{paddingBottom: '0'}}>
                            <Input
                                placeholder="First Name"
                                name="first_name"
                                onChange={handleDataChange}
                                style={{
                                    background: 'transparent', 
                                    borderRadius: '8px', 
                                    width: '100%'
                                }}
                            >
                                <input
                                    style={{
                                        padding: '1rem', 
                                        width: '100%', 
                                        fontSize: '1.2rem', 
                                        borderRadius: '8px'
                                    }} 
                                />
                            </Input>
                            {errors.first_name && (
                                <p style={{ color: '#FF6347', fontSize: '0.9rem', textAlign: 'left' }}>
                                    {errors.first_name}
                                </p>
                            )}
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Input
                                placeholder="Last Name"
                                name="last_name"
                                onChange={handleDataChange}
                                style={{
                                    background: 'transparent', 
                                    borderRadius: '8px', 
                                    width: '100%'
                                }}
                            >
                                <input
                                    style={{
                                        padding: '1rem', 
                                        width: '100%', 
                                        fontSize: '1.2rem', 
                                        borderRadius: '8px'
                                    }} 
                                />
                            </Input>

                            {errors.last_name && (
                                <p style={{ color: '#FF6347', fontSize: '0.9rem', textAlign: 'left' }}>
                                    {errors.last_name}
                                </p>
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Input
                                placeholder="Company Name"
                                name="client_name"
                                onChange={handleDataChange}
                                style={{
                                    background: 'transparent', 
                                    borderRadius: '8px', 
                                    width: '100%'
                                }}
                            >
                                <input
                                    style={{
                                        padding: '1rem', 
                                        width: '100%', 
                                        fontSize: '1.2rem', 
                                        borderRadius: '8px'
                                    }} 
                                />
                            </Input>
                            {errors.conflict && (
                                <p style={{ color: '#FF6347', fontSize: '0.9rem', textAlign: 'left' }}>
                                    {errors.conflict}
                                </p>
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Input
                                icon
                                placeholder="Password (8+ characters)*"
                                type={visible ? 'text' : 'password'}
                                name="password"
                                onChange={handleDataChange}
                                style={{
                                    background: 'transparent', 
                                    borderRadius: '8px', 
                                    width: '100%'
                                }}
                            >
                                <input
                                    style={{
                                        padding: '1rem',
                                        width: '100%', 
                                        fontSize: '1.2rem', 
                                        borderRadius: '8px'
                                    }} 
                                />
                                <Icon
                                    name={visible ? 'eye' : 'eye slash'}
                                    onClick={() => setVisible(!visible)}
                                    link
                                />
                            </Input>
                            {errors.password && (
                                <p style={{ color: '#FF6347', fontSize: '0.9rem', textAlign: 'left' }}>
                                    {errors.password}
                                </p>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Button
                    disabled={loading}
                    onClick={onSubmit}
                    style={{
                        width: '100%',
                        background: "#21C8AA",
                        color: 'white',
                        margin: '1.2rem 0 0',
                        padding: '1.1rem 0',
                        borderRadius: '8px',
                        fontWeight: '600',
                        fontSize: '1.2rem',
                    }}
                >
                    {loading ? <div className="custom-loader"></div> : 'Continue'}
                </Button>
               </> 
            ) }
        </div>
    );
};

export default Register;
