import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import ResumeUpload from './ResumeUploadForm';
import DetailsForm from './DetailsForm';
import axios from '../../../api/axiosInstance';
import { getHeader } from '../../../types';
import Swal from 'sweetalert2';
import Validator from "validator";

import { useDispatch, useSelector } from 'react-redux';
import { addTalentToTalentPool } from '../../../actions/action_talent_pool';
import { addTalentToTalentPoolFromJob } from '../../../actions/action_job';
import { tagOptionAction } from '../../../actions/action_option';
import { validateNumberInput } from '../../../OptionData';


let initialState = {
  first_name: "",
  last_name: "",
  email: "",
  designation: "",
  mobile: "",
  currentLocation: "",
  expectedLocation: [],
  currentSalary: "",
  expectedSalary: "",
  totalExperience: 0,
  internalRating: 0,
  // job_id,
  client_id: "",
  client_name: "",
  companyId: "",
  resume: "",
  role: {
    type: "",
    subCategory: "",
  },
  currentCurrencyType: "",
  preferenceCurrencyType: "",
  parsedResumeData: {},
  searchTag: [],
  communicationScore: "",
  trustScore: "",
  noticePeriod: "",
  additionalCompensationNotes: "",
  additonalExpectedCompensationNotes: "",
  typeOfEmployment: {
    Permanent: true,
    Contract: false,
    Fresher: false,
    Freelance: false,
    Intern: false,
    Experts: false,
  },
}
export default function AddTalentModal({ open, close, jobId, displayPortal}) {

    const dispatch = useDispatch();

    const resumeFile = useRef(null);

    const user = useSelector(state => state.user.user);
    const tagOptions = useSelector((state) => state.options.tags);

    let talentPartnerDetails = {
      name: user.first_name + " " + user.last_name,
      email: user.email,
      company_id : user.company_id
    }

    const [step, setStep] = useState(0);
    const [ errors, setErrors ] = useState({});
    const [loading, setLoading] = useState(false);
    const [addtalentdata, setAddtalentdata] = useState(initialState);

    useEffect(() => {
      if(tagOptions.length < 1) {
        dispatch(tagOptionAction());
      }
    }, []);

    useEffect(() => {
      setErrors(() => validate(addtalentdata));
    }, [addtalentdata]);

    const handleClose = () => {
      setAddtalentdata(initialState);
      setStep(0);
      setLoading(false);
      resumeFile.current = null;
      close();
    }

    const onChangeResume = async (e) => {
        e.preventDefault();
        setLoading(true);
        const resume = e.target.files[0];

        resumeFile.current = resume;
        let parsedData;
        try {
          parsedData = await fetchResumeParserData(resume);
        } catch (error) {
          console.log('An error occurred:', error);
          displayPortal("An error occurred while parsing the resume", false);
          setLoading(false);
        }
        console.log("parsedData: ",parsedData, jobId);

        if(!parsedData.email) {
          setAddtalentdata({
            ...addtalentdata,
                first_name: parsedData.first_name,
                last_name: parsedData.last_name,
                email: parsedData.email,
                designation: parsedData.designation,
                mobile: parsedData.mobile,
                totalExperience: parsedData.totalExperience,
                education: parsedData.education,
                workExperience: parsedData.workExperience,
                currentCompany: parsedData.currentCompany,
              });
          setStep(1);
          return;
        }
        //set parsedData in state

        //check the email address first
        const emailCheck = await checkIfEmailExistInJobOrTalent(parsedData.email, jobId);

        console.log("Email", emailCheck);
        // if(parsedData.email){
        // jobid and talent exist in job
        // jobid, talent doesn't exist in job
        if(jobId && emailCheck.status === 209) {
          displayPortal('email is already exists in the job', false);
          close();
          return;
        };
          // even if talent exists in job or in talent pool, we can edit it.
        if(emailCheck.status === 202) {
          let talent = emailCheck.data.talent;
          Swal.fire({
            title: "This Talent already exists",
            text:
              emailCheck.data.message ||
              "Email Id is already exists in system. Will you like to edit it?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#21C8AA",
            confirmButtonText: "Edit",
            // onClose: navigate("/signup"),
          }).then((result) => {
            if(result.isConfirmed) {
              // edit talent 
              setAddtalentdata({
                first_name: talent.firstName,
                last_name: talent.lastName,
                email: talent.email,
                designation: talent.currentDesignation,
                mobile: talent.contactNumber,
                currentLocation: talent.currentLocation,
                expectedLocation: talent.preferredLocation,
                currentSalary: talent.currentSalary,
                expectedSalary: talent.preferredSalary,
                totalExperience: talent.experience,
                internalRating: 0,
                currentCompany: talent.currentCompany,
                typeOfEmployment: talent.typeOfEmployment,
                searchTag: talent.searchTag.map(item => tagOptions.find(tag => tag.value === item)) || [],
                potentials: talent.potentials,
                resume: talent.resume,
                role: {
                  role_type: talent.role?.role_type,
                  subCategory: talent.role?.subCategory,
                },
                preferenceCurrencyType: talent.preferenceCurrencyType || "INR",
                currentCurrencyType: talent.currentCurrencyType || "INR",
                communicationScore: talent.potentials.communication_score || "",
                trustScore: talent.potentials.trust_score || "",
                qrataAnalysis: "",
                noticePeriod: talent.noticePeriod,
                lastWorkingDay: talent.lastWorkingDay || "",
                additionalCompensationNotes: talent.additionalCompensationNotes,
                additonalExpectedCompensationNotes: talent.additonalExpectedCompensationNotes
              });
              resumeFile.current = null;
              setStep(1);
            }
          })
        } else {
          setAddtalentdata({
            ...addtalentdata,
                first_name: parsedData.first_name,
                last_name: parsedData.last_name,
                email: parsedData.email,
                designation: parsedData.designation,
                mobile: parsedData.mobile,
                totalExperience: parsedData.totalExperience,
                education: parsedData.education,
                workExperience: parsedData.workExperience,
                currentCompany: parsedData.currentCompany,
                preferenceCurrencyType: "INR",
                currentCurrencyType: "INR",
              });
          setStep(1);
        }

            console.log('email', emailCheck);
        // }
        setLoading(false);
    };

   

    const handleSubmit = () => {
      setLoading(true);
      const updatedData = {
        ...addtalentdata,
        potentials: {
          communication_score: addtalentdata.communicationScore,
          trust_score: addtalentdata.trustScore,
        },
      };

      const recrit = {
        recruiter_email: talentPartnerDetails.email,
        recruiter_name: talentPartnerDetails.name,
        // recruite_id: recruite_id,
        // pod_group: pod_group,
      };

      if(Object.keys(errors).length > 0) {
        return;
      }
      console.log(updatedData,"updatedData frm")

      if(jobId) {
        dispatch(addTalentToTalentPoolFromJob({
          recruiterDetail: recrit,
              job_id: jobId,
              companyId: user.company_id,
              data: updatedData,
              file: resumeFile.current
        }))
        .then((response) => {  
        console.log("response from job", response);
        handleClose();
        displayPortal("New Candidate has been added.", true);
        setLoading(false);
        })
        .catch(err => {
          console.log("error", err);
          displayPortal('something went wrong', false);
          setLoading(false);
          handleClose();
        })
      } else {

        dispatch(addTalentToTalentPool({data: updatedData, file: resumeFile.current}, talentPartnerDetails, true ))
        .then(response => {
          console.log("response", response);
          handleClose();
          displayPortal("New Candidate has been added.", true);
          setLoading(false);
        })
        .catch(error => {
          console.log("error", error);
          displayPortal("Something went wrong! Contact developers.", false);
        })
      }
      // setStep(0);
      // close();
    };

    console.log("resume file", resumeFile.current)

  return (
    <>
      <Modal size='large' closeIcon open={open} onClose={handleClose}>
          <Modal.Header style={{color: '#645B5B'}}>
              Add a new talent
          </Modal.Header>
          <Modal.Content>
              {
                  step === 0 && <ResumeUpload resumeFile={resumeFile} onChangeResume={onChangeResume} loading={loading} />
              }
              {
                  step === 1 && <DetailsForm addtalentdata={addtalentdata} setAddtalentdata={setAddtalentdata} errors={errors} />
              }
          </Modal.Content>
          <Modal.Actions>  
              {step === 0 &&  <Button disabled={!resumeFile.current || loading} style={{ background: "#21C8AA", color: 'white'}} onClick={() => setStep(1)}>Next</Button> }
              {/* {step === 1 &&  <Button onClick={() => setStep(0)}>Back</Button> } */}
              {step === 1 &&  <Button disabled={Object.keys(errors).length > 0} style={{ background: "#21C8AA", color: 'white'}} onClick={handleSubmit}>Submit</Button> }
          </Modal.Actions>
      </Modal>
    </>
  )
};

const fetchResumeParserData = async (resume) => {
  let formData = new FormData();
  formData.append('resume', resume);
  formData.append('resumeName', resume.name);
  let headers = {
    ...getHeader(),
    // ...formData.getHeaders(),
    'Content-Type': 'multipart/form-data'
  };
  console.log("headers", headers);
  const response = await axios.post('/api/parser', formData, { headers });
  console.log('response from parser', response);
  return response.data;
};

const checkIfEmailExistInJobOrTalent = async (email, jobId) => {
  try {
    const response = await axios.post('/api/talent/add-talent-new/step-after-parse-resume', { email, job_id: jobId}, { headers: getHeader()});
    console.log('response from axios', response);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle 404 error specifically
      console.log("Email not found in Job or Talent collections.");
      return error.response;
  }
  }
};


const validate = (data) => {
  const errors = {};
  console.log("Validation", data.first_name.length)
  if (!data.first_name) errors.first_name = "First name is required!";
  else if(data.first_name.length > 20) errors.first_name = "First name must be less than 20 characters";
  if (!data.last_name) errors.last_name = "Last name is required!";
  if (!data.email) errors.email = "Email is required!";
  else if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
  if (!data.designation) errors.designation = "Designation is required!";
  if (!data.mobile) errors.mobile = "Mobile is required!";
  if (!data.currentLocation) errors.currentLocation = "Location is required!";
  if (!data.currentCompany)
    errors.currentCompany = "Current company is required!";
  if (!data.currentSalary || !validateNumberInput(data.currentSalary))
    errors.currentSalary = "Current Salary should be 4 digit or less!";
  if (!data.expectedSalary || !validateNumberInput(data.expectedSalary))
    errors.expectedSalary = "Expected Salary should be 4 digit or less!";
  if (!data.noticePeriod) errors.noticePeriod = "Notice period is required";
  // if (!data.role.role_type) errors.role = "Role is required!";
  // if (!data.role.subCategory) errors.role = "Role is required!";

  // if (!data.role) errors.role = "Role is required!";

  return errors;
};
