import { Button, Input, Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import google from "../images/search.png"
import axios from "axios";
import Register from "./Register";
import { getInitialize } from "../../../../actions/action_auth";
import Password from "./Password";
import { signin } from "../../../../actions/action_auth";
import Validator from 'validator';
import './custom.css'


const GettingStarted = () => {
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const allClients = useSelector((state)=> state.client.allClient)
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [client, setClient] = useState();
  const prev = localStorage.getItem('prevURL') || null;
  
  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      console.log("token for OAuth recieved : ", token);
      dispatch(getInitialize()).then((res) => {
        console.log("it is happing from here 222", res.data.response)
        if (res.data.response?.status === 500 || 401 || 403 || 404){
        console.log("it is happing from here 222 eeeeeeeeee iiiii", res.data.response.status)
          localStorage.removeItem("accessToken");
 } else {
          navigate("/jobs");
        }
      });
    } else {
      console.log("Access token not present in localStorage");
    }
  }, [navigate]);
  console.log(process.env.REACT_APP_API_URL,"asdkdhgkj")
  const handleGoogle = () => {
    const googleSignUrl = `${process.env.REACT_APP_API_URL}/api/auth/google-login`;
    window.open(googleSignUrl, "_self");
  };

  const validateEmail = () => {
    const validationErrors = {};

    if (!Validator.isEmail(data.email)) {
      validationErrors.email = "Invalid email format";
    }

    // const emailPattern =
    //   /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|yahoo\.co\.in|aol\.com|live\.com|outlook\.com)[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]{2,61}$/;

    // if (!emailPattern.test(data.email)) {
    //   validationErrors.email = "Please enter a valid business email";
    // }

    setErrors(validationErrors);
    return validationErrors;
  };

const handleDataChange = (e) => {
    const { name, value } = e.target;
setData(prevData => ({
    ...prevData,
    [name]: value
}))

if ((name === "email" || name === "password") && value === "") {
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
    }));
}
}

  const handleEmailSubmit = async () => {
    setLoading(true);
    setErrorMessage(''); 

    const validationErrors = validateEmail();
    if (Object.keys(validationErrors).length > 0) {
      setLoading(false);
      return; // Stop if there are validation errors
    }
    
    if (!data.email) {
        setErrorMessage('Please enter an email address.');
        setLoading(false);
        return;
    }

    try {
        const response = await axios.post(`/api/auth/check-if-account-exists`, { email: data.email });
        if(response.data && response.data.accountExists) {
            setStep(1)
        }else {
            setStep(2)
        }
        console.log(response, 'exist email response')
    } catch (error) {
        console.error("Error checking if account exists:", error);
        if(error?.response?.data?.reason==="invalid_business_email"){
        // setErrorMessage(error?.response?.data?.message)
            const emailPattern = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|yahoo\.co\.in|aol\.com|live\.com|outlook\.com)[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]{2,61}$/;

            if (!emailPattern.test(data.email)) {
              validationErrors.email = "Please enter a valid business email";
            }
        }
        else setErrorMessage('Something went wrong. Please try again.');
    } finally {
        setLoading(false);
    }
    
};

const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        handleEmailSubmit();
    }
};


const onSubmit = async () => {
    setLoading(true);

    try {
        const response = await dispatch(signin(data));
        const user = response.payload.user;
        const userRole = user.role;

        if (userRole === "partner" || userRole === "partnerClient") {
            navigate("/partner-talentpool");
        } else if (userRole === "client") {
            const clientId = user.clientId;
            const token = localStorage.getItem("accessToken");

            // Fetch client details
            const clientData = allClients.find(client => client._id === clientId);

            setClient(clientData);

            if (
                !clientData?.clientName ||
                !clientData?.contactNo ||
                !clientData?.countryCode ||
                !clientData?.industry ||
                !clientData?.designationOfContactPerson
            ) {
                navigate("/client/about");
            } else {
              console.log("previous page url", prev);
                  if(prev) {
                    navigate(prev);
                    localStorage.removeItem('prevURL');
                  } else {
                    navigate("/dashboard");
                  }
            }
        } else {
            navigate("/jobs");
        }
    } catch (error) {
        if (error.response && error.response.data) {
            const responseErrors = error.response.data;
            if (responseErrors.error === "Email not provided!") {
                setErrors({ email: responseErrors.error });
            } else if (responseErrors.error === "This email does not exist") {
                setErrors({ email: responseErrors.error });
            } else if (responseErrors.error === "User is not activated") {
                setErrors({ email: responseErrors.error });
            } else {
                setErrors({ password: responseErrors.error });
            }
        }
    } finally {
        setLoading(false);
    }
};

console.log(errors, 'checing errors')

  return (
    <div style={{
        borderTop: "1px solid #00000040",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px #00000040",
        marginTop: "10px",
        padding: "0 2rem 2.5rem",
        position: 'relative'
      }}
    >
      {step === 0 && (
          <div
            style={{
              color: '#3D3737',
              textAlign: 'center',
            }}
          >
            <p style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '0.8rem 0 0.2rem' }}>Get started in seconds</p>
            <p style={{ margin: '0 0 1.2rem' }}>Use your email or another service to get started with Qrata!</p>
            <div style={{ marginTop: '1rem' }}>
              <Button
                id='loginWithGoogle'
                variant='contained'
                fullWidth
                size='large'
                onClick={handleGoogle}
                style={{
                  textTransform: 'none',
                  color: '#3f3f3f',
                  background: '#fff',
                  border: '1px solid #645B5B',
                  marginBottom: '1rem',
                  fontWeight: '600',
                  borderRadius: '8px',
                  padding: '1rem 0',
                  fontSize: '1.1rem',
                  width: '100%', // Force full width
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img src={google} width='20px' style={{ marginRight: '10px' }} />
                Login with Google
              </Button>

              <div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
                    <div style={{width: '100%', background: '#B3B3B3', height: '1.5px'}}></div>
                    <p style={{color: '#B3B3B3', margin: '0'}}>OR</p>
                    <div style={{width: '100%', background: '#B3B3B3', height: '1.5px'}}></div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginTop: '0.8rem',
                }}
              >
               <input
                  placeholder="Company Email"
                  type="email"
                  name='email'
                  value={data.email}
                  onKeyDown={handleKeyDown}
                  onChange={handleDataChange}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                    padding: "1.2rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    border: "1px solid #b3b3b3",
                    // boxSizing: "border-box",
                  }}
                />
                {errors.email && <p style={{ color: 'red', marginTop: '-7px'}}>{errors.email}</p>}
                {errorMessage && <p>{errorMessage}</p>}

                <Button
                    onClick={handleEmailSubmit}
                    variant='contained'
                    fullWidth
                    size='large'
                    style={{
                    textTransform: 'none',
                    color: '#fff',
                    background: '#21C8AA',
                    //   border: '1px solid #645B5B',
                    marginBottom: '1rem',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    padding: '1.2rem 0',
                    fontSize: '1.1rem',
                    display: 'block',
                    width: '100%',
                    }}
              >
                {loading ? (
                  <div className="custom-loader"></div>
                ) : (
                  'Continue with email'
                )}
              </Button>
              </div>

              <p
                style={{
                  color: '#645B5B',
                //   marginTop: '-7px',
                  textAlign: 'left',
                  fontSize: '1rem',
                }}
              >
                By creating an account you consent to Qrata's{' '}
                <a
                  target="_blank"
                  href="http://qrata.ai/terms-service.html"
                  rel="noreferrer"
                  style={{
                    color: '#0E4B76',
                    fontWeight: '400',
                    textDecoration: 'none',
                  }}
                >
                  <b>Terms & Conditions</b>
                </a>{' '}
                and{' '}
                <br />
                <a
                  target="_blank"
                  href="http://qrata.ai/privacy-policy.html"
                  rel="noreferrer"
                  style={{
                    color: '#0E4B76',
                    fontWeight: '400',
                    textDecoration: 'none',
                  }}
                >
                  {' '}
                  <b>Privacy Policy</b>
                </a>
              </p>

            </div>
          </div>
      )}

      {step === 1 && <Password setStep={setStep} setLoading={setLoading} loading={loading} data={data} handleDataChange={handleDataChange} onSubmit={onSubmit} errors={errors}/> }
      {step === 2 && <Register email={data.email} setStep={setStep} setEmail={setEmail}/> }
      {step === 0 &&<div style={{background: 'red', position: 'absolute', bottom: '-20px', width: '100%', right: '0', background: '#CFEBFF', borderRadius: "0 0 10px 10px", padding: '0.7rem 2rem', boxShadow: "0px 4px 4px 0px #00000040"}}>
        <p style={{color: '#3D3737', fontSize: '1rem', fontWeight: 'bold'}}>Looking to grow? <span><a href="https://talent.qrata.ai/" style={{color: '#003458', textDecoration: 'underline'}}> Continue as a talent</a></span></p>
      </div>}
    </div>
  );
};

export default GettingStarted;
