import axios from "./axiosInstance";
import { getHeader } from "./../types.js";

export default {
  talent_pool: {
    getAllTalentApi: (page, filter) =>
      axios
        .post(`/api/talentpool/allCandidate?page=${page}`, filter, {
          header: getHeader(),
        })
        .then((res) => res.data),
    getAllTalentOfCustomPoolApi: (poolId) =>
      axios.get(`/api/talentpool/custom-talent-pool-candidates/${poolId}`, {
        header: getHeader(),
      })
      .then((res) => res.data),
    getTalentFilter: (page, filter) =>
      axios
        .post(`/api/talentpool/searchtalent?page=${page}`, filter, {
          header: getHeader(),
        })
        .then((res) => res.data),
    //     searchFilterApi:(page, filter) =>
    //                         axios.post(`/api/talentpool/searchtalent?=${page}`,filter, {header:getHeader()}).then(res=>res.data),
    getJobForTalentPoolApi: (c_Id, client_Id) =>
      axios
        .get(
          `/api/talentpool/jobtitle/`,
          { params: { id: c_Id, client_Id: client_Id } },
          { header: getHeader() }
        )
        .then((res) => res.data),
    addTalentToTalentPoolApi: (data) =>
      axios
        .post(`/api/talentpool/addtalent`, data, { header: getHeader() })
        .then((res) => console.log("api res", data)),
    sendMailToTalentsNowApi: (emails, mailContent, companyid) =>
      axios
        .post(
          `/api/talentpool/sendMail/${companyid}`,
          { emails: emails, content: mailContent },
          { header: getHeader() }
        )
        .then((res) => res.data),
        getSavedSearchAndCustomPoolApi: () => 
          axios.get('/api/talentpool/saved-search', { headers: getHeader()}).then(res => res.data),
        createSavedSearchApi: (data) => 
          axios.post('/api/talentpool/saved-search',data, { headers: getHeader()}).then(res => res.data),
        editSavedSearchApi: (searchId, data) => 
        axios.put(`/api/talentpool/saved-search/${searchId}`,data, { headers: getHeader()}).then(res => res.data),
        deleteSavedSearchAndCustomPoolApi: (searchId) => 
          axios.delete(`/api/talentpool/saved-search/${searchId}`, { headers: getHeader()}).then(res => res.data),
        createCustomPoolApi: (data) => 
          axios.post('/api/talentpool/saved-search',data, { headers: getHeader()}).then(res => res.data),
        editCustomPoolApi: (searchId, data) => 
        axios.post(`/api/talentpool/saved-search/${searchId}`,data, { headers: getHeader()}).then(res => res.data),
        addMultiCandidateToCustomPoolApi: (poolId, multipleCandidates) =>
          axios.put(`/api/talentpool/add-talents-to-custom-pool/${poolId}`, { idsArray: multipleCandidates}, { headers: getHeader()}).then(res => res.data),
        // axios.post(`/api/talentpool/saved-search/${searchId}`,data, { headers: getHeader()}).then(res => res.data),
          
  },
};

// getJobForTalentPoolApi: (c_Id,client_Id) =>
// axios.get(`/api/talentpool/jobtitle/${c_Id}`, { header: getHeader()}).then(res => res.data),

// talent_pool : {
//         getAllTalentApi : (data, id) =>
//                 axios.post(`/api/admin/allcandidate`, data, { header: getHeader()}).then(res => res.data),

// talent_pool : {
//         getAllTalentApi : (data, id) =>
//                 axios.post(`/api/talentpool/allcandidate/${id}`, data, { header: getHeader()}).then(res => res.data),
