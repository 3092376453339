import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Header,
  Image,
  Modal,
  Icon,
  GridColumn,
  Grid,
  Card,
} from "semantic-ui-react";
import { editAdminBasicInfo, EditResumeUpload } from "../../../../../actions/action_application";
import { getHeader } from "../../../../../types";
import axios from "axios";
function EditResumeModal({ talentId }) {
  console.log("application id in resume", talentId);

  const [open, setOpen] = React.useState(false);
  const [resume, setResume] = React.useState(null);
  // const [parseData, setParsedData]=React.useState({})

    const { individual_candidate, loading, application_stage } = useSelector(
      (state) => state.application
    );

   const [talentCardBioInfo, setTalentCardBioInfo] = React.useState({
    contactNumber: "",
    currentLocation: "",
    workLinks: [], 
    preferredLocation: [], 
    experience: "",  
    timeCommitment: "",  
    currentCompany: "", 
    timeCommitmentType: "",
    email:"",
    education:[],
    workExperience:[],
    education:[]
    });

    console.log("dsfhdsfjbahm", individual_candidate.bio.email)
  const dispatch = useDispatch();

  const fetchResumeParserData = async (resume) => {
    let formData = new FormData();
    console.log(resume,"dfkjhadsjbfahsbd ")
    formData.append('resume', resume);
    formData.append('resumeName', resume.name);
    let headers = {
      ...getHeader(),
      // ...formData.getHeaders(),
      'Content-Type': 'multipart/form-data'
    };
    console.log("headers", headers);
    const response = await axios .post('/api/parser', formData, { headers });
    console.log('response from parser', response);
    // return response.data
    setTalentCardBioInfo({
      contactNumber: response.data?.mobile,
      currentLocation: response.data?.currentLocation,
      workLinks: response.data?.workLinks,
      preferredLocation: response.data?.preferredLocation ? [preferredLocation] : [],
      experience: response.data?.totalExperience,
      timeCommitment: response.data?.timeCommitment,
      currentCompany: response.data?.currentCompany,
      timeCommitmentType: response.data?.timeCommitmentType,
      email:individual_candidate.bio.email,
      education:response.data?.education,
      workExperience:response.data?.workExperience,
      education:response.data?.education
    })
  };

  const onChangeDocumentFile = async(e, isAutoFill) => {
    setResume(e.target.files[0]);
    console.log("dsfahkjsdb", )
    let parsedData;
    try {
      parsedData = await fetchResumeParserData(e.target.files[0]);
      console.log(parsedData,"dfahdsjha")
    } catch (error) {
      console.log('An error occurred:', error);
      // displayPortal("An error occurred while parsing the resume", false);
      // setLoading(false);
    }
  };

  const submitResume = async (e) => {
    await dispatch(EditResumeUpload(resume, talentId));
    dispatch(editAdminBasicInfo(talentCardBioInfo, talentId, null));
    setOpen(false);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div>
          <Button basic color="teal" floated="right" style={{width:'100px'}}>
            {/* <Icon name="edit" size="medium" /> */}
            Edit
          </Button>
        </div>
      }
    >
      <Modal.Header className="Greencolr">
        <Grid>
          <GridColumn computer={8}>
            <h3>Edit Resume</h3>
          </GridColumn>
          <GridColumn computer={8} style={{ textAlign: "end" }}>
            {/* <Button onClick={() => setOpen(false)}> */}
              <Icon onClick={() => setOpen(false)} style={{ cursor:"pointer"}}
              //  name="window close outline"
              name="close"
                />
            {/* </Button> */}
          </GridColumn>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p><b>Note:</b> Resume wont get parsed</p>
          <br />
          <Card
            fluid
            style={{
              border: "2px dashed",
              boxShadow: "none",
              padding: "3%",
              textAlign: "center",
            }}
          >
            <input
              style={{
                height: "150px",
                marginTop: "-44px",
                paddingTop: "45px",
                paddingLeft: "45%",
                border: "none",
                background: "transparent",
              }}
              type="file"
              // accept=".pdf"
              // onChange={(e) => setResume(e.target.files[0])}
              onChange={(e)=>onChangeDocumentFile(e)}
              name="document"
            />
            <h3>Upload A New Resume</h3>
          </Card>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={resume ? false : true}
          className="Greencolr"
          onClick={() => submitResume()}
        >
          Confirm
        </Button>
        {/* <Button
          content="Yep, that's me"
          labelPosition="right"
          icon="checkmark"
          onClick={() => setOpen(false)}
          positive
        /> */}
      </Modal.Actions>
    </Modal>
  );
}

export default EditResumeModal;
