import React, { useEffect, useState } from 'react'
import axiosInstance, { setupAxiosInterceptors } from '../api/axiosInstance';  // Import Axios and the setup function
import RetryAfterPopup from './RetryAfterPopup';

const excludePopupEndpoints = [
  '/api/talent/resumeInsights',
  '/api/talent/stabilityRatings',
  '/api/talent/gptInsights',
  '/api/talent/gptRatings',
  '/api/talent/capabilityRating',
  '/api/gpt/capabilityRating',
  '/api/gpt'
];

export default function tooManyRequestModal({ children }) {
    const [retryAfter, setRetryAfter] = useState(null);  // Track the retry time
    const [showPopup, setShowPopup] = useState(false);  // Control the popup visibility
  
    // Function to trigger the popup
    const triggerPopup = (retryAfterSeconds) => {
      setRetryAfter(retryAfterSeconds);  // Set the retry-after value
      setShowPopup(true);  // Show the popup
    };
  
    // Close the popup manually (optional)
    const handleClosePopup = () => {
      setShowPopup(false);
    };
  
    // Set up the Axios interceptor when the component mounts
    useEffect(() => {
      // Axios response interceptor to handle rate limiting
      const interceptor = axiosInstance.interceptors.response.use(
          response => response,
          error => {
              if (error.response && error.response.status === 429) {
                  console.error('Rate limit hit. Blocking further requests.');
                  const requestUrl = new URL(error.config.url, axiosInstance.defaults.baseURL).pathname;
                  const cleanedUrl = requestUrl.replace(/\/[^/]+$/, "");
                  console.log("cleanedUrl: ", cleanedUrl);
        // Check if popup should be skipped for this API
                  const shouldSkipPopup = excludePopupEndpoints.includes(cleanedUrl);
                  const retryAfterHeader = error.response.headers['retry-after'];
                  const retryAfter = retryAfterHeader ? parseInt(retryAfterHeader, 10) : 60;

                  if(!shouldSkipPopup){
                    triggerPopup(retryAfter);
                  }
              }
              return Promise.reject(error);
          }
      );

      // Cleanup the interceptor on component unmount
      return () => {
          axiosInstance.interceptors.response.eject(interceptor);
      };
  }, []);
  
  
    return (
      <div>
        {showPopup && (
          <RetryAfterPopup retryAfter={retryAfter} onClose={handleClosePopup} />
        )}
        {
            !showPopup && children
        }
      </div>
    );
  };
