import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Icon,
  Button,
  Checkbox,
} from "semantic-ui-react";
import moment from "moment";
import Swal from "sweetalert2";

const shortenUrl = async (longUrl) => {
  const apiEndpoint = `https://api.tinyurl.com/create`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer l8RVzxtI8phIlj1wv8eMEZlW7rEJSaoBINTmMHUdB2jNT6LbRvYj6c5ZoXvY", // Replace with your TinyURL API token
      },
      body: JSON.stringify({
        url: longUrl,
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data.data.tiny_url;
  } catch (error) {
    console.error("Error shortening URL:", error);
    return null;
  }
};

export default function CustomPoolTable({
  activeIndex,
  data,
  onCandTalentCheckBox,
  emailIds,
  handleShowPool,
  handleDelete,
}) {
  const [shareLoading, setShareLoading] = useState(false);
  const handleCopyLink = async (poolId) => {
    setShareLoading(true);
    let url = `${window.location.origin}/talent-pool/${poolId}/custom`;
    const shortenedUrl = await shortenUrl(url);
    navigator.clipboard.writeText(shortenedUrl);
    setShareLoading(false);
    return Swal.fire({
      title: 'Link has been copied successfully',
      timer: 1500
    })
    // setCopyText(true);
   
  };

  const dataToShow =
    activeIndex === 1
      ? data.filter((item) => item.show_on_qrata_website)
      : data;
  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderCell></TableHeaderCell>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell># of profiles</TableHeaderCell>
          <TableHeaderCell>Pool type</TableHeaderCell>
          {/* <TableHeaderCell>Shared to</TableHeaderCell> */}
          <TableHeaderCell></TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableHeader>

        {(data.length < 1 || !data) && <TableBody>No data found</TableBody>}

        <TableBody>
          {data.length > 0 &&
            dataToShow.map((d, index) => (
              <TableRow key={index}>
                {/* {!isPublic && data.source === "talent" ? ( */}
                <Table.Cell>
                  <Checkbox
                    label=""
                    onChange={() => onCandTalentCheckBox(d._id)}
                    checked={emailIds.includes(d._id)}
                  />
                </Table.Cell>
                {/* ) : !isPublic && candidate.source === "candidate" ? (
                  <div className="table-cell row-span-2"></div>
                ) : null} */}
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Icon name="search" />
                    <div>
                      <h4 style={{ margin: 0 }}>{d.title}</h4>
                      <p style={{ color: "#b3b3b3" }}>
                        Created on {moment(d.createdAt).format("LL")}
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>{d.no_of_profiles}</TableCell>
                <TableCell>{d.visibleTo}</TableCell>
                {/* <TableCell>5 view</TableCell> */}
                <TableCell>
                  <Button
                    className="show_qrata_button"
                    disabled={d.visibleTo !== "Public(Global)"}
                    onClick={() =>
                      handleShowPool(d._id, d.show_on_qrata_website)
                    }
                  >
                    {d.show_on_qrata_website ? (
                      <Icon name="star" color="yellow" />
                    ) : (
                      <Icon name="star outline" />
                    )}
                    {/* <Icon name={d.show_on_qrata_website === true ? 'star' : 'star outline'} /> */}
                    Show on Qrata.ai
                  </Button>
                </TableCell>
                <TableCell
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Icon
                    style={{ color: "#645B5B", cursor: 'pointer' }}
                    size="large"
                    name="share alternate"
                    disabled={d.visibleTo !== "Public(Global)"}
                    // loading={shareLoading}
                    onClick={() => handleCopyLink(d._id)}
                  />
                  {/* &nbsp;&nbsp; */}
                  {activeIndex === 0 && (
                    <Icon
                      size="large"
                      color="red"
                      name="trash alternate"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete([d._id])}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
