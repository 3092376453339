export const skills = [
  { key: 1, value: "Javascript", label: "Javascript" },
  { key: 2, value: "Java", label: "Java" },
  { key: 3, value: "AWS", label: "AWS" },
  { key: 4, value: "Ansible", label: "Ansible" },
  { key: 5, value: "React", label: "React" },
  { key: 6, value: "Node", label: "Node" },
  { key: 7, value: "Angular5", label: "Angular5" },
  { key: 8, value: "Express", label: "Express" },
  { key: 9, value: "D3js", label: "D3js" },
  { key: 10, value: "Redis", label: "Redis" },
  { key: 11, value: "Python", label: "Python" },
  { key: 12, value: "Web Scraping", label: "Web Scraping" },
  { key: 13, value: "Machine Learning", label: "Machine Learning" },
  { key: 14, value: "Algorithms", label: "Algorithms" },
  { key: 15, value: "Data Structures", label: "Data Structures" },
];

export const CurrencyType = [
  { key: "INR", text: "INR", value: "INR" },
  { key: "USD", text: "USD", value: "USD" },
  { key: "EUR", text: "EUR", value: "EUR" },
  { key: "GBP", text: "GBP", value: "GBP" },
  { key: "AED", text: "AED", value: "AED" },
];
export const Role = [
  { key: 1, text: "Select One", value: "Select One" },
  { key: 2, text: "Design ", value: "Design" },
  { key: 3, text: "Data Analytics", value: "Data Analytics" },
];
export const companyPreference = [
  { key: 1, text: "Start-up", value: "Start-up" },
  { key: 2, text: "Corporate", value: "Corporate" },
  { key: 3, text: "Industry", value: "Industry" },
];

export const employmentType = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Intern", label: "Intern" },
  { value: "Contract", label: "Contract" },
  { value: "Freelance", label: "Freelance" },
];

export const noticePeriodOptions = [
  { key: 1, value: "Available Immediately", label: "Available Immediately" },
  { key: 7, label: "Serving notice period", value: "serving notice period"},
  { key: 2, value: "Less than 15 days", label: "Less than 15 days" },
  { key: 3, value: "1 month", label: "1 month" },
  { key: 4, value: "45 days", label: "45 days" },
  { key: 5, value: "2 months", label: "2 months" },
  { key: 6, value: "3 months", label: "3 months" },
];

export const searchType = [
  { key: 1, value: 'Public', text: 'Public' },
  { key: 2, value: 'Private', text: 'Private' },
  { key: 3, value: 'Public(Global)', text: 'Public(Global)' },
];

export const CurrencyTypeCheck = (currentCurrencyType) => {
  if (currentCurrencyType === "INR") {
    return "lpa";
  } else if (
    currentCurrencyType === "USD" ||
    currentCurrencyType === "EUR" ||
    currentCurrencyType === "GBP" ||
    currentCurrencyType === "AED"
  ) {
    return "k/yr";
  } else {
    return "";
  }
};

export function validateNumberInput(input) {
    // Convert input to string to handle cases like backspace or empty values
    const inputString = input.toString();
    
    // Regular expression to allow only 0 to 4 digit positive numbers
    const isValid = /^[0-9]{0,4}(\.[0-9]*)?$/.test(inputString);
  
    return isValid;
  }
