import React, { useState } from 'react';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  FormInput,
  FormGroup,
  Form,
  Grid,
  Dropdown,
  Image,
  Modal,
  Input
} from 'semantic-ui-react';
import ReactSelect from "react-select";
import { CurrencyType } from '../../OptionData';
import Resume from '../New-TalentCard/BasicDetails/PersonalDetails/ResumeModal/Resume'
import EditResumeModal from '../New-TalentCard/BasicDetails/PersonalDetails/ResumeModal/EditResumeModal';
import axios from 'axios';
import moment from "moment";
import { useDispatch,useSelector } from 'react-redux';
import { getMappedCandidatesById } from '../../actions/action_application';
import {addTalentToTalentPool } from '../../actions/action_talent_pool'
import Swal from 'sweetalert2';
import CandidateEye from '../../images/svg/candidate-eye.svg'


function MappedPoolCandidateModal({ candidate }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ ...candidate });  
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const firstName = useSelector(state => state.user?.user?.first_name)
    const lastName = useSelector(state => state.user?.user?.last_name)
    const recuriterEmail = useSelector(state => state.user?.user?.email)
    const recruiterName = `${firstName} ${lastName}`
    const recruit = {
      recruiter_email: recuriterEmail,
      recruiter_name: recruiterName,
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    console.log( e.target,"dsfdsfds")
    setData((prevData) => ({
      ...prevData,
      [name]: value,
      workLinks: {
        ...prevData.workLinks,
        [name]: value  
      }
    }));

  };
  const onChangeLocation = (e, { value }) => {
    setData({
      ...data,
      location: value,
    });
  };

  const parseResume = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onload = async () => {
            const binaryData = reader.result.split(',')[1]; 
            const data = JSON.stringify({
                filedata: `${binaryData}`,
                filename: `${file.name}`,
                userkey: "LJUTM85ZJNO",
                version: "8.0.0",
                subuserid: "Keshav Kumar",
            });
            const config = {
                method: "post",
                url: "https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary",
                headers: { "Content-Type": "application/json" },
                data: data,
            };
            delete axios.defaults.headers.common["Authorization"];
            try {
                const response = await axios(config);
                resolve(response.data.ResumeParserData);
                console.loh(response.data.ResumeParserData,"jhdtydythfhg")
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = (error) => reject(error);
    });
};

  const dispatch = useDispatch()

  const getFields = (mappedCandidate) => {
    const fieldsOrder = [
        "mappedBy", 
        "name",
        "email",
        "contact",
        "designation",
        "company",
        "yearsOfExperience",
        "location",
        "linkedin",
        // "degree",
        // "institute",
    ]
    const fieldsToIgnore = ["updatedAt", "createdAt", "_id", "mappingId", "relevant", 'notes', 'comments', '__v', 'image', 'experience', 'education', 'mappingIds']
    for(let b of mappedCandidate){
        for(let key in b) {
            if(b[key] && !fieldsToIgnore.includes(key)){
                if(!fieldsOrder.includes(key)){
                  fieldsOrder.push(key)  
                }
            }
        }

    }

    return fieldsOrder
    
}
const locationDropdown = useSelector(
  (state) => state.options.locationOptions
);

console.log(locationDropdown,"sdfdsfsd")
  const handleResumeChange = async (e) => {
    const file = e.target.files[0];
    let parsedData
    if(file) {

        setFile(file);
        parsedData = await parseResume(file)
    }
    if (parsedData) {
    let skills = [];
    let experienceFromParsedData = [];
    let qualificationFromParsedData = [];
    if (parsedData.SegregatedSkill.length > 0) {
        parsedData.SegregatedSkill.map((skill, key) =>
        skills.push({ value: skill.Skill, label: skill.Skill, key })
        );
    }
    if ("SegregatedExperience" in parsedData) {
        parsedData.SegregatedExperience.map((experience) => {
        experienceFromParsedData.push({
            location:
            experience.Location.City + ", " + experience.Location.Country,
            ctc: "",
            currencyType: "",
            company: experience.Employer.EmployerName,
            designation: experience.JobProfile.Title,
            description: experience.JobDescription,
            startDate: moment(experience.StartDate, "DDMMYYYY").format(
            "MMMM YYYY"
            ),
            endDate: moment(experience.EndDate, "DDMMYYYY").format(
            "MMMM YYYY"
            ),
        });
        });
    }
    if ("SegregatedQualification" in parsedData) {
        parsedData.SegregatedQualification.map((qualification) => {
        qualificationFromParsedData.push({
            universityName: qualification.Institution.Name,
            degree: qualification.Degree.DegreeName,
            startDate: moment(qualification.StartDate, "DDMMYYYY").format(
            "MMMM YYYY"
            ),
            endDate: moment(qualification.EndDate, "DDMMYYYY").format(
            "MMMM YYYY"
            ),
            major: "",
            description: "",
            gpa: null,
        });
        });
    }
    delete parsedData["TemplateOutput"];
    delete parsedData["HtmlResume"];
    delete parsedData["Qualification"];
    delete parsedData["DetailResume"];

    console.log(data,"dsfsdfkjsdkfjsdhfkjsd")
    
    setData({
        first_name: data.first_name        ,
        // last_name: parsedData.Name.LastName,
        email: data.email ? data.email : parsedData.Email[0].EmailAddress,
        designation: parsedData.JobProfile,
        mobile:
          data.contact ? data.contact : parsedData.PhoneNumber.length > 0
            ? parsedData.PhoneNumber[0].Number
            : null,
        totalExperience: parseInt(
          parsedData.WorkedPeriod.TotalExperienceInYear
        ),
        summary: parsedData.Summary,
        currentCompany: parsedData.CurrentEmployer,
        skills: skills,
        currentLocation: "",
        expectedLocation: [],
        currentSalary: "",
        expectedSalary: "",
        internalRating: 0,
        resume: "",
        role: {
          type: "",
          subCategory: "",
        },
        currentCurrencyType: "",
        workExperience: experienceFromParsedData,
        education: qualificationFromParsedData,
        parsedResumeData: {
          educationResume: [],
          workExperience: [],
          resumeAnalytics: {},
          // AllParsedData: parsedData,
        },
        communicationScore: "",
        trustScore: "",
        noticePeriod: "",
      });
    // console.log(parsedData, 'parseData')
    }
};
  const handleFileUpload = async (candidateId) => {
    
    const formData = new FormData();
    formData.append('resume', file);

    setLoading(true);

    try {
      const response = await axios.post(`/api/candidate/upload/resume/${candidateId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response,"resume response")

      setLoading(false);

      Swal.fire({
        title: data.candidateId ? 'Candidiate updated and Upload Successful' : 'New Candidiate created and Upload Successful',
        text:  data.candidateId ? 'Candidiate updated successfully. new file has been successfully uploaded.' :  "New candidate created successfully and file uploading is also successful.",
        icon: 'success',
        confirmButtonColor: '#21C8AA',
        confirmButtonText: 'OK'
      });
      setFile(null)
    } catch (error) {
      setLoading(false);
      console.error('Error uploading file:', error);
      Swal.fire({
        title: 'Upload Failed',
        text: 'An error occurred while uploading the file. Please try again later.',
        icon: 'error',
        confirmButtonColor: '#21C8AA',
        confirmButtonText: 'OK'
      });
    }
};

  const handleSave= async () => {
    console.log(data, "Saved data", candidate._id    );
    const updateddata = {
      ...data,
      name:data.first_name,
      currentCTC:data.currentSalary,
expectedCTC:data.expectedSalary,
linkedin:data.linkedinLink,
yearsOfExperience:data.experience,
      company: data?.company?.trim()
  }
  
  try {
    console.log( data,"Candidate updated successfully:", updateddata);

    if(candidate?.source == "candidate") {
      console.log( data,"Candidate updated successfully: if", updateddata);
      
      const response = await axios.put(`/api/candidate/${candidate._id}`, updateddata);
console.log(response,"dfdsfdsfsaddsf", updateddata)
        if(response.data && file){
          console.log("fdsfdsfdsfads",response.data)
            await handleFileUpload(response.data._id)
        }
        
        if(response.data && !file){
            Swal.fire({
                title: 'Candidate Updated',
                text: 'Candidate updated successfully',
                icon: 'success',
                confirmButtonColor: '#21C8AA',
                confirmButtonText: 'OK'
              });
        }
        if (file && data.email && data.mobile) {
            console.log(file,'condition met', data)
            const updatedData = { ...data };
            await dispatch(addTalentToTalentPool({
                data: updatedData,
                file: file
            }, 
            recruit, 
            true
        ))
    }

    }
} catch (error) {
    console.error("Error adding candidate:", error);
} finally {

}
    setOpen(false); 
  };

  console.log(data,"sdfdssdsdgs")
   
  const handleAddCandidate = async () => {
    const updateddata = {
        ...data,
        company: data?.company?.trim()
    }
    let linkedinExists = false;
    try {
        if (!data.candidateId && data.linkedin) {
            linkedinExists = mappedCandidates.find(candidate => candidate.linkedin === data.linkedin);
        }      
        if (linkedinExists) {
            Swal.fire({
                title: 'Duplicate LinkedIn Link',
                text: 'This LinkedIn link already exists for another candidate.',
                icon: 'error',
                confirmButtonColor: '#21C8AA',
                confirmButtonText: 'OK'
            });
            return;
        }
        setLoading(true)
        const hasRequiredFields = data.name 
        // && data.email && data.contact
        if(!hasRequiredFields){
            Swal.fire({
                title: 'Please enter all required fields',
                text: ' Name is a required field',
                icon: 'error',
                confirmButtonColor: '#21C8AA',
                confirmButtonText: 'OK'
            });
            return ;
        }

        if(data.candidateId) {
            const response = await axios.put(`/api/candidate/${data._id}`, updateddata);
            console.log("Candidate updated successfully:", response.data);

            if(response.data && file){
                await handleFileUpload(response.data._id)
            }
            
            if(response.data && !file){
                Swal.fire({
                    title: 'Candidate Updated',
                    text: 'Candidate updated successfully',
                    icon: 'success',
                    confirmButtonColor: '#21C8AA',
                    confirmButtonText: 'OK'
                  });
            }

            if (hasRequiredFields && file) {
                console.log('condition met')
                const updatedData = { ...data };
                await dispatch(addTalentToTalentPool({
                    data: updatedData,
                    file: file
                }, 
                recruit, 
                true
            ))
        }

        }
    } catch (error) {
        console.error("Error adding candidate:", error);
    } finally {
        setLoading(false)
        if (!linkedinExists) {
            setData({
                ...data,
                candidateId: '',
                // mappedBy: recruiterName ? recruiterName : '',
                first_name: "",
                email: "",
                contact: "",
                designation: "",
                company: "",
                yearsOfExperience: "",
                location: "",
                linkedin: "",
                // mappingId: id,
                degree: "",
                institute: "",
                resume: null,
                github: "",
                currentCTC: "",
                expectedCTC: "",
                portfolio: "",
            });
            setFile(null);
        }
    }
};

const name=data.first_name
const phone=data.contact

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Image src={CandidateEye} style={{height:"23px"}}/>}
    >
      <ModalHeader>EDIT TALENT</ModalHeader>
      <ModalContent>
        <Form>
          <FormGroup widths='equal'>
          <FormInput
  fluid
  label='Name*'
  placeholder='Name'
  name={data.name ? "name" : "first_name"}
  value={data.name || data.first_name} 
  onChange={onChange}
/>
            <FormInput
              fluid
              label='Year of Experience'
              placeholder='Year of Experience'
              name='experience' 
              value={data.experience || ''} 
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup widths='equal'>
            <FormInput
              fluid
              label='Designation'
              placeholder='Designation'
              name='currentDesignation' 
              value={data.designation || data.currentDesignation}
              onChange={onChange}
            />
            <FormInput
              fluid
              label='Company'
              placeholder='Company'
              name='company'
              value={data.company || ''}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup widths='equal'>
            <FormInput
              fluid
              label='Email'
              placeholder='Email'
              name='email'
              value={data.email || ''}
              onChange={onChange}
            />
            <FormInput
              fluid
              label='Phone No.'
              placeholder='Phone No.'
              name='contact' 
              value={data.contact || ''}
              onChange={onChange}
            />
          
          </FormGroup>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormInput
                  fluid
                  label='LinkedIn Link'
                  placeholder='LinkedIn Link' 
                  name='linkedinLink'
                  value={data.workLinks?.linkedinLink || ''}
                  onChange={onChange}
                />
              </Grid.Column>
              <Grid.Column width={8} style={{display:'grid', paddingLeft:'8px'}}>
                <label style={{fontWeight:'700'}}>Resume</label>
                  <Input type="file" name="resume" onChange={handleResumeChange}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FormGroup widths='equal'>
            <FormInput
              fluid
              label='GitHub'
              placeholder='GitHub'
              name='github' 
              value={data.github|| ''}
              onChange={onChange}
            />
            <FormInput
              fluid
              label='Portfolio'
              placeholder='Portfolio'
              name='portfolio'
              value={data.portfolio || ''}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup widths='equal' style={{display:'grid', padding:'0px 10px'}}>
            {/* <FormInput
              fluid
              label='Location'
              placeholder='Location'
              name='location' 
              value={data.currentLocation || ''}
              onChange={onChange}
            /> */}
            <label style={{fontWeight:'700'}}>Current Location</label>
                      <Dropdown
                        search
                        selection
                        fluid
                        name="location"
                        value={data.location || ""}
                        options={
                          locationDropdown &&
                          locationDropdown.map((location) => ({
                            key: location._id,
                            text: location.location,
                            value: location.location,
                          }))
                        }
                        onChange={onChangeLocation}
                      />
                      {console.log(data,"Sdfdsfas")}
          </FormGroup>
          <FormGroup widths='equal'>
            <FormInput
              fluid
              label='Degree'
              placeholder='Degree'
              name='degree'
              value={data.degree || ''}
              onChange={onChange}
            />
            <FormInput
              fluid
              label='Institute'
              placeholder='Institute'
              name='institute'
              value={data.institute || ''}
              onChange={onChange}
            />
          </FormGroup>
          <Grid>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Current Compensation</label>
                <Grid columns="equal" style={{ marginTop: "0.1rem" }}>
                  <Grid.Column width={4}>
                    <Dropdown
                      placeholder="Currency"
                      selection
                      compact
                      options={CurrencyType}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <input
                      type="number"
                      name="currentSalary" 
                      placeholder="10"
                      style={{
                        padding: "0.7rem 0.5rem",
                        borderRadius: "5px",
                        border: "1px solid #B2B3B3",
                      }}
                      value={data.currentSalary}
                      onChange={onChange}
                    />
                  </Grid.Column>
                </Grid>
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Expected Compensation</label>
                <Grid columns="equal" style={{ marginTop: "0.1rem" }}>
                  <Grid.Column width={4}>
                    <Dropdown
                      placeholder="Currency"
                      selection
                      compact
                      options={CurrencyType}

                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <input
                      type="number"
                      name="expectedSalary" 
                      placeholder="10"
                      style={{
                        padding: "0.7rem 0.5rem",
                        borderRadius: "5px",
                        border: "1px solid #B2B3B3",
                      }}
                      value={data.expectedSalary || data.expectedCTC}
                      onChange={onChange}
                    />
                  </Grid.Column>
                </Grid>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
      </ModalContent>
      <ModalActions>
        <Button color='grey' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button positive onClick={handleSave}>
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default MappedPoolCandidateModal;
