import React from 'react'
import { Button, Container, Header, Image, Segment } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'  // Use for navigation
import ExpiryLink from '../images/svg/Link-ExpiryImg.svg'

const LinkExpiry = () => {
    const navigate = useNavigate()  // Hook to handle redirection

    const handleRedirect = () => {
        navigate('/jobs')  // Redirect to the "jobs" page
    }

    return (
        <Container textAlign="center" style={{ minHeight: '85vh', alignItems:"center", display:'flex' }} fluid>
            <Segment padded="very" style={{ margin: '0 auto', textAlign: 'center', boxShadow:"none", border:"none", padding:"0px" }}>
                <Image src={ExpiryLink} centered style={{height:"230px", width:"auto"}} />
                
                <Header as="h3" style={{ marginTop: '20px', color:"#003458" }}>
                    Link Expired
                </Header>

                <Header as="h5" style={{ marginTop: '10px', color: '#555', lineHeight: '1.6', fontWeight:"100" }}>
                    This link is no longer available (valid for 7 days).
                    <br />
                    Need access? Contact your account manager or email <a href="mailto:customer@qrata.ai">customer@qrata.ai</a> for assistance.
                </Header>

                <Button 
                    onClick={handleRedirect} 
                    primary 
                    style={{ marginTop: '15px', background:"#21c8aa" }}>
                    Go to Jobs
                </Button>
            </Segment>
        </Container>
    )
}

export default LinkExpiry
