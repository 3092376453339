import React, { useEffect, useState } from "react";
import {
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Button,
  FormField,
} from "semantic-ui-react";
import axios from "../../api/axiosInstance.js";

export default function PoolLimitModal({ open, close, value, setValue }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .put("/api/global-values", { pool_limit_on_qrata_website: value })
      .then((res) => {
        setLoading(false);
        close();
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Modal open={open} onClose={close}>
        <ModalHeader>Pool limit on Qrata.ai</ModalHeader>
        <ModalContent>
          <FormField>
            <label for="pool-limt">Pool limit</label>
            <Input
              type="number"
              id="pool-limit"
              min="1"
              onChange={(e, { value }) => setValue(value)}
              value={value}
            />
          </FormField>
        </ModalContent>
        <ModalActions>
          <Button loading={loading} onClick={handleSubmit}>
            Save
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
}
