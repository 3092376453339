import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon, Pagination } from "semantic-ui-react";
import { setPage } from "../../actions/action_talent_pool";

const PaginationShorthand = () => {
  const dispatch = useDispatch();
  const { totalCandidates, page } = useSelector(
    (state) => state.talent_pool
  );
  const numbes = useSelector(
    (state) => state.talent_pool
  );
  console.log(numbes,'dfsdsdsdfasd')

  return (
    <div>
      {totalCandidates > 0 ? (
        <Pagination
          defaultActivePage={page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={{
            content: <Icon name="angle double left" />,
            icon: true,
          }}
          lastItem={{
            content: <Icon name="angle double right" />,
            icon: true,
          }}
          prevItem={{
            content: <Icon name="angle left" />,
            icon: true,
          }}
          nextItem={{
            content: <Icon name="angle right" />,
            icon: true,
          }}
          onPageChange={(event, { activePage }) =>
            dispatch(setPage(activePage))
          }
          totalPages={Math.floor(totalCandidates / 20)}
        />
      ) : null}
      {totalCandidates === 0 ? (
        <center style={{ height: "55vh" }}>
          <span style={{ color: "red" }}>No candidate found</span>
        </center>
      ) : null}
    </div>
  );
};

export default PaginationShorthand;
