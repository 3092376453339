import React, { useEffect, useState } from "react";
import {
  Grid,
  Form,
  Checkbox,
  Button,
  Dropdown,
  Icon,
  Input,
} from "semantic-ui-react";
import InlineError from "../../../messages/InlineError";
import { useSelector } from "react-redux";
import Select from "react-select";
import SelectCreate from "react-select/creatable";

import './DetailsForm.css'
import { CurrencyType, CurrencyTypeCheck } from "../../../OptionData";

const communicationScore = [
  { key: 0, text: "-NA-", value: null },
  { key: 1, text: "Low", value: "Low" },
  { key: 2, text: "Medium", value: "Medium" },
  { key: 3, text: "High", value: "High" },
];

const trustScore = [
  { key: 0, text: "-NA-", value: null },
  { key: 1, text: "Low", value: "Low" },
  { key: 2, text: "Medium", value: "Medium" },
  { key: 3, text: "High", value: "High" },
];

const noticePeriodOptions = [
  { key: 0, text: "-NA-", value: null },
  { key: 7, text: "Serving notice period", value: "serving notice period"},
  { key: 1, text: "Available Immediately", value: "Available Immediately" },
  { key: 2, text: "Less than 15 days", value: "Less than 15 days" },
  { key: 3, text: "1 month", value: "1 month" },
  { key: 4, text: "45 days", value: "45 days" },
  { key: 5, text: "2 months", value: "2 month" },
  { key: 6, text: "3 month", value: "3 month" },
];

export default function DetailsForm({
  addtalentdata,
  setAddtalentdata,
  errors
}) {

  const roleDropdown = useSelector((state) => state.options.roleOptions);
  const tagOptions = useSelector((state) => state.options.tags);
  const locationDropdown = useSelector(
    (state) => state.options.locationOptions
  );

  const [location, setLocation] = useState("");

  const onChange = (e) => {
    setAddtalentdata({ ...addtalentdata, [e.target.name]: e.target.value });
  };

  const handleChangeJobType = (e, value) => {
    console.log("job control1111 eeee", e.target.innerHTML, value.checked);
    if (e.target.innerHTML === "Contract")
      setAddtalentdata({
        ...addtalentdata,
        typeOfEmployment: {
          ...addtalentdata.typeOfEmployment,
          Contract: value.checked,
          Freelance: value.checked,
        },
      });
    // setJobTypes({...jobTypes, Contract: value.checked, Freelance: value.checked})
    else if (e.target.innerHTML === "Full-Time")
      setAddtalentdata({
        ...addtalentdata,
        typeOfEmployment: {
          ...addtalentdata.typeOfEmployment,
          Permanent: value.checked,
        },
      });
    else if (e.target.innerHTML === "Fresher")
      setAddtalentdata({
        ...addtalentdata,
        typeOfEmployment: {
          ...addtalentdata.typeOfEmployment,
          Fresher: value.checked,
        },
      });
    else if (e.target.innerHTML === "Experts")
      setAddtalentdata({
        ...addtalentdata,
        typeOfEmployment: {
          ...addtalentdata.typeOfEmployment,
          Experts: value.checked,
        },
      });
  };

  const onChangePreferredLocation = (address) => {
    setLocation(address.value);
  };
  const handleLocation = (e) => {
    e.preventDefault();
    if (location !== "") {
      setAddtalentdata({
        ...addtalentdata,
        expectedLocation: [...addtalentdata.expectedLocation, location],
      });

      setLocation("");
    }
  };

  const deleteLocation = (e, index) => {
    e.preventDefault();

    // filteredData = this.state.
    setAddtalentdata({
      ...addtalentdata,
      expectedLocation: addtalentdata.expectedLocation.filter(
        (loc) => loc !== addtalentdata.expectedLocation[index]
      ),
    });
  };

  const addHyphen = (tag) => {
    // alert("dfj")
    console.log("tags", tag);
    // let str = e.target;
    // let ele = str.split("-").join(""); // Remove dash (-) if mistakenly entered.
    // if (ele.trim() == "") {
    setAddtalentdata({ ...addtalentdata, searchTag: tag });
    // this.setState({
    // data: {
    //     ...this.state.data,
    //     searchTag: ele
    // }
    // })
    // } else {
    // let finalVal = ele.match(/.{1,3}/g).join("-");
    // setAddtalentdata({ ...addtalentdata, [e.target.name]: finalVal });
    // this.setState({
    //     data: {
    //         ...this.state.data,
    //         searchTag: finalVal
    //     }
    // })
    // }
  };

  const handleChangeCurrency = (e, data) => {
    e.preventDefault();
    console.log('value on change of currency', data)
    setAddtalentdata({
      ...addtalentdata,
      [data.name]: data.value,
    });
  };

  const handleCommunicationScore = (e, { value }) => {
    e.preventDefault(),
      setAddtalentdata({
        ...addtalentdata,
        communicationScore: value,
      });
  };

  const handleTrustScore = (e, { value }) => {
    e.preventDefault(),
      setAddtalentdata({
        ...addtalentdata,
        trustScore: value,
      });
  };

  const handleNoticePeriod = (e, { value }) => {
    e.preventDefault(),
      setAddtalentdata({
        ...addtalentdata,
        noticePeriod: value,
        lastWorkingDay: ""
      });
  };

  const onChangeLocation = (e, { value }) => {
    setAddtalentdata({
      ...addtalentdata,
      currentLocation: value,
    });
  };

  const handleChangeLastWorkingDay = (e, { value }) => {
    console.log("last working day", value);
    setAddtalentdata({
      ...addtalentdata,
      lastWorkingDay: value
    })
  }

  const today = new Date().toISOString().split('T')[0];

  console.log("data of talent", addtalentdata);

  return (
    <React.Fragment>
      <Form>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column width={6}>
              <Form.Field>
                <label>First Name</label>
                <input
                  name="first_name"
                  id="first_name"
                  onChange={onChange}
                  value={addtalentdata.first_name}
                  placeholder="First name"
                />
                {errors.first_name && (
                  <InlineError text={errors.first_name} />
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Field>
                <label>Last Name</label>
                <input
                  name="last_name"
                  id="last_name"
                  onChange={onChange}
                  value={addtalentdata.last_name}
                  placeholder="Last name"
                />
                {errors.last_name && <InlineError text={errors.last_name} />}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Form.Field>
                <label>Email</label>
                <input
                  name="email"
                  id="email"
                  value={addtalentdata.email}
                  onChange={onChange}
                  placeholder="Email"
                  // disabled
                />
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Field>
                <label>Contact No.</label>
                <input
                  name="mobile"
                  id="mobile"
                  onChange={onChange}
                  value={addtalentdata.mobile}
                  placeholder="Mobile"
                />
                {errors.mobile && <InlineError text={errors.mobile} />}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Form.Field>
                <label>Current Company</label>
                <input
                  name="currentCompany"
                  id="company"
                  onChange={onChange}
                  value={addtalentdata.currentCompany}
                />
                {errors.currentCompany && (
                  <InlineError text={errors.currentCompany} />
                )}
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={5}>
              <Form.Field>
                <label>Designation</label>
                <input
                  name="designation"
                  id="designation"
                  onChange={onChange}
                  value={addtalentdata.designation}
                  placeholder="designation"
                />
                {errors.designation && (
                  <InlineError text={errors.designation} />
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Field>
                <label>Years of Experience</label>
                <input
                  type="number"
                  min="0"
                  name="totalExperience"
                  id="experience"
                  onChange={onChange}
                  value={
                    parseInt(addtalentdata.totalExperience)
                      ? parseInt(addtalentdata.totalExperience)
                      : 0
                  }
                  placeholder="Total Experience"
                />
                {errors.experience && (
                  <InlineError text={errors.experience} />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={10}>
              <Form.Field>
                <label>Role</label>
                <Grid columns="equal">
                  <Grid.Column>
                    <Dropdown
                      placeholder="Role"
                      fluid
                      selection
                      onChange={(e, { value }) =>
                        setAddtalentdata({
                          ...addtalentdata,
                          role: {
                            ...addtalentdata.role,
                            role_type: value,
                          },
                        })
                      }
                      options={roleDropdown.standardRole}
                      value={addtalentdata.role?.role_type}
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: "-19px" }}>
                    <label style={{ fontWeight: "bold" }}>Sub-role</label>
                    <Dropdown
                      fluid
                      selection
                      onChange={(e, { value }) =>
                        setAddtalentdata({
                          ...addtalentdata,
                          role: {
                            ...addtalentdata.role,
                            subCategory: value,
                          },
                        })
                      }
                      options={
                        addtalentdata.role?.role_type
                          ? roleDropdown.subCategories
                              .find(
                                (category) =>
                                  category.standardizedRole ===
                                  addtalentdata.role.role_type
                              )
                              .subCategories.map((item) => ({
                                key: item,
                                text: item,
                                value: item,
                              }))
                          : []
                      }
                      value={addtalentdata.role?.subCategory}
                    />
                    {errors.role && <InlineError text={errors.role} />}
                  </Grid.Column>
                </Grid>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Current Location</label>
                <Dropdown
                  search
                  selection
                  fluid
                  value={addtalentdata.currentLocation}
                  options={
                    locationDropdown &&
                    locationDropdown.map((location) => ({
                      key: location._id,
                      text: location.location,
                      value: location.location,
                    }))
                  }
                  onChange={onChangeLocation}
                />
                {errors.currentLocation && (
                  <InlineError text={errors.currentLocation} />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column width={7}>
              <Form.Field>
                <label>Notice Period</label>
                <Dropdown
                  onChange={handleNoticePeriod}
                  options={noticePeriodOptions}
                  placeholder="Select Notice Period"
                  selection
                  value={addtalentdata.noticePeriod}
                />
                {errors.noticePeriod && (
                  <InlineError text={errors.noticePeriod} />
                )}
              </Form.Field>
            </Grid.Column>
            {
              addtalentdata.noticePeriod === 'serving notice period' &&
            <Grid.Column width={4}>
              <label>Last working day</label>
              <Input type="date" value={addtalentdata.lastWorkingDay} name="last working day" min={today} onChange={handleChangeLastWorkingDay} />
            </Grid.Column>
            }
          </Grid.Row>

          <Grid.Column width={15}>
            <Form.Field>
              <label>Preferred Location</label>
              <ul>
                {addtalentdata.expectedLocation?.map((location, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <li key={index}>{location}</li>
                      <Icon
                        name="delete"
                        onClick={(e) => deleteLocation(e, index)}
                      />
                    </div>
                  );
                })}
              </ul>
              <div style={{ display: "flex", flexGrow: "1" }}>
                <div style={{ width: "100%", marginRight: "1rem" }}>
                  <Select
                    options={
                      locationDropdown &&
                      locationDropdown.map((location) => {
                        return {
                          key: location._id,
                          value: location.location,
                          label: location.location,
                        };
                      })
                    }
                    value={addtalentdata.preferredLocation}
                    onChange={onChangePreferredLocation}
                  />
                </div>
                <Button icon onClick={(e) => handleLocation(e)}>
                  <Icon name="add" />
                </Button>
              </div>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={15}>
            <Form.Field>
              <label>Employment Type</label>
              <div style={{ margin: "20px 0px 0px 0px" }}>
                <Checkbox
                  checked={addtalentdata?.typeOfEmployment?.Permanent}
                  value={addtalentdata?.typeOfEmployment?.Permanent}
                  onChange={handleChangeJobType}
                  name="Full-Time"
                  label="Full-Time"
                />
                <Checkbox
                  checked={addtalentdata?.typeOfEmployment?.Contract}
                  value={addtalentdata?.typeOfEmployment?.Contract}
                  onChange={handleChangeJobType}
                  name="Contract"
                  style={{ marginLeft: "5%" }}
                  label="Contract"
                />
                <Checkbox
                  checked={addtalentdata?.typeOfEmployment?.Fresher}
                  value={addtalentdata?.typeOfEmployment?.Fresher}
                  onChange={handleChangeJobType}
                  style={{ marginLeft: "5%" }}
                  name="Fresher"
                  label="Fresher"
                />
                <Checkbox
                  checked={addtalentdata?.typeOfEmployment?.Experts}
                  value={addtalentdata?.typeOfEmployment?.Experts}
                  onChange={handleChangeJobType}
                  style={{ marginLeft: "5%" }}
                  name="Experts"
                  label="Experts"
                />
              </div>
            </Form.Field>
          </Grid.Column>

          {/* CURRENT SALARY STARTS */}
          {/* <Grid.Row>
            <Grid.Column width={2}>
              <Form.Field>
                <label>Current Compensation</label>
                <Dropdown
                  onChange={handleChangeCurrency}
                  options={options}
                  placeholder="Currency"
                  selection
                  compact
                  value={addtalentdata.currentCurrencyType}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={2}>
              <Grid.Column width={8}>
                <Input
                  type="number"
                  name="currentSalary"
                  placeholder="10"
                  value={addtalentdata.currentSalary}
                  // style={{
                  //   padding: "0.7rem 0.5rem",
                  //   borderRadius: "5px",
                  //   border: "1px solid #B2B3B3",
                  // }}
                  onChange={onChange}
                />
              </Grid.Column>
            <Grid.Column width={8}>
                {relativeInput}
            </Grid.Column>
            </Grid.Column>
            <Grid.Column width={6}>
                <Input
                  type="text"
                  name="additionalCompensationNotes"
                  placeholder="Ex: 30% fixed"
                  value={addtalentdata.additionalCompensationNotes}
                  onChange={onChange}
                  // style={{
                  //   padding: "0.7rem 0.5rem",
                  //   borderRadius: "5px",
                  //   border: "1px solid #B2B3B3",
                  // }}
                />
            </Grid.Column>
          </Grid.Row> */}

          <Grid.Row>
            <Grid.Column width={8}>
            <Form.Field>
            <label>Current compensation (fixed)</label>
            <Grid>
              <Grid.Column width={3}>
                <Dropdown
                  name="currentCurrencyType"
                  onChange={handleChangeCurrency}
                  options={CurrencyType}
                  selection
                  compact
                  value={addtalentdata.currentCurrencyType}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <input
                  type="number"
                  name="currentSalary"
                  placeholder="10"
                  value={addtalentdata.currentSalary}
                  style={{
                    padding: "0.7rem 0.5rem",
                    borderRadius: "5px",
                    border: "1px solid #B2B3B3",
                  }}
                  onChange={onChange}
                />

                {errors.currentSalary && (
                  <InlineError text={errors.currentSalary} />
                )}
              </Grid.Column>

              <Grid.Column computer={2} textAlign="left">
                {CurrencyTypeCheck(addtalentdata.currentCurrencyType)}
              </Grid.Column>
            </Grid>
          </Form.Field>
            </Grid.Column>
          <Grid.Column width={8}>
          <Form.Field>
              <label>CTC Breakup</label>
              <Grid>
                <Grid.Column>

                <input
                  type="text"
                  name="additionalCompensationNotes"
                  placeholder="Ex: 30% fixed"
                  value={addtalentdata.additionalCompensationNotes}
                  onChange={onChange}
                  // style={{
                  //   padding: "0.7rem 0.5rem",
                  //   borderRadius: "5px",
                  //   border: "1px solid #B2B3B3",
                  // }}
                />
                </Grid.Column>
              </Grid>
            </Form.Field>
          </Grid.Column>
          </Grid.Row>
          {/* CURRENT SALARY ENDS */}

          {/* EXPECTED SALARY STARTS */}
          <Grid.Row>  
          <Grid.Column width={8}>
            <Form.Field>
              <label>Expected compensation</label>
              <Grid>
                <Grid.Column width={3}>
                  <Dropdown
                    name="preferenceCurrencyType"
                    onChange={handleChangeCurrency}
                    options={CurrencyType}
                    selection
                    compact
                    value={addtalentdata.preferenceCurrencyType}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <input
                    type="number"
                    name="expectedSalary"
                    placeholder="10"
                    value={addtalentdata.expectedSalary}
                    onChange={onChange}
                    style={{
                      padding: "0.7rem 0.5rem",
                      borderRadius: "5px",
                      border: "1px solid #B2B3B3",
                    }}
                  />

                  {errors.expectedSalary && (
                    <InlineError text={errors.expectedSalary} />
                  )}
                </Grid.Column>
                <Grid.Column computer={4} textAlign="left">
                  {CurrencyTypeCheck(addtalentdata.preferenceCurrencyType)}
                </Grid.Column>
              </Grid>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
              <label>Compensation Notes</label>
              <Grid>
              <Grid.Column>
                <input
                  type="text"
                  name="additonalExpectedCompensationNotes"
                  placeholder="Ex: As per industry standards"
                  value={addtalentdata.additonalExpectedCompensationNotes}
                  onChange={onChange}
                  // style={{
                  //   padding: "0.7rem 0.5rem",
                  //   borderRadius: "5px",
                  //   border: "1px solid #B2B3B3",
                  // }}
                />
              </Grid.Column>
              </Grid>
            </Form.Field>
          </Grid.Column>
          </Grid.Row>
          {/* EXPECTED SALARY ENDS */}

          <Grid.Row>
            <Grid.Column width={5} style={{ marginRight: "10px" }}>
              <Form.Field>
                <label>Communication Score</label>
                <Grid.Column>
                  <Dropdown
                    onChange={handleCommunicationScore}
                    options={communicationScore}
                    placeholder="Select Score"
                    selection
                    value={addtalentdata.communicationScore}
                    style={{ width: "300px", marginTop: "1rem" }}
                  />
                </Grid.Column>
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={5}>
              <Form.Field>
                <label>Trust Score</label>
                <Grid.Column>
                  <Dropdown
                    onChange={handleTrustScore}
                    options={trustScore}
                    placeholder="Select Score"
                    selection
                    value={addtalentdata.trustScore}
                    style={{ width: "300px", marginTop: "1rem" }}
                  />
                </Grid.Column>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: "1rem" }}>
            <Grid.Column width={15}>
              <Form.Field>
                <label>Add tags</label>
                <SelectCreate
                  options={tagOptions}
                  isMulti
                  value={addtalentdata.searchTag}
                  onChange={addHyphen}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </React.Fragment>
  );
};
