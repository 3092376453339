import axios from 'axios';
// prod url
// axios.defaults.baseURL = 'https://hireapi.qrata.ai';

// dev url
axios.defaults.baseURL = 'https://dev-hireapi.qrata.ai';

// staging url
// axios.defaults.baseURL = 'https://staging.app.qrata.ai';

// localhost url
//  axios.defaults.baseURL = 'http://localhost:5002';

const instance = axios.create();
// axios.defaults.baseURL = 'https://dev-hireapi.qrata.ai';
// axios.defaults.baseURL = 'http://localhost:5002';
// axios.defaults.baseURL="https://hireapi.qrata.ai"
// const instance = axios.create({
//     baseURL: 'http://localhost:5002',
// });

let rateLimitActive = false;  // Global flag to block further requests
let rateLimitResetTimeout = null;  // Timer to reset the rate limit
let sessionExpired = false;
const retryRequest = (error, retryAfter) => {
    console.log(`Retrying the request in ${retryAfter} seconds...`);
  
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Retry the original request after the timeout
        instance.request(error.config)
          .then(resolve)  // Resolve the promise when retry is successful
          .catch(reject); // Reject the promise if it fails again
      }, retryAfter * 1000); // Convert seconds to milliseconds
    });
  };
  instance.interceptors.request.use(
    config => {
      if (rateLimitActive) {
        console.log('API requests are currently blocked due to rate limiting.');
        return Promise.reject({ message: 'Rate limit active, request blocked.' });
      }
      if (sessionExpired) {
        console.log('API requests are currently blocked due to session expiration.');
        return Promise.reject({ message: 'Session expired, request blocked.' });
      }
      return config;
    },
    error => Promise.reject(error)
  );
  
  // Axios response interceptor to handle responses
  instance.interceptors.response.use(
    response => response,
    error => {
      console.log("error.response : ", error.response);
      if (error.response.status === 401) {
        if (!sessionExpired) {
          sessionExpired = true; // Set the sessionExpired flag
          alert('Your session has expired');
          window.location.replace('/signin');
        }
      }
      if (error.response.status === 429) {
        console.error('Rate limit hit. Blocking further requests.');
        // Get the retry-after value (in seconds), fallback to 60 seconds if undefined
        const retryAfterHeader = error.response.headers['retry-after'];
        const retryAfter = retryAfterHeader ? parseInt(retryAfterHeader, 10) : 60;
        rateLimitActive = true;
        setTimeout(() => {
          rateLimitActive = false;
        }, retryAfter * 1000);
      }
      return Promise.reject(error);
    }
  );
  
  // export const setupAxiosInterceptors = (triggerPopup, resetRateLimit) => {
      
  //     // Axios request interceptor to block requests during rate-limiting
  //     instance.interceptors.request.use(
  //       config => {
  //         if (rateLimitActive) {
  //           console.log('API requests are currently blocked due to rate limiting.');
  //           return Promise.reject({ message: 'Rate limit active, request blocked.' });
  //         }
  //         if (sessionExpired) {
  //           console.log('API requests are currently blocked due to session expiration.');
  //           return Promise.reject({ message: 'Session expired, request blocked.' });
  //         }
  //         return config;
  //       },
  //       error => Promise.reject(error)
  //     );


  //     instance.interceptors.response.use(response => {return response}, error => {
  //         console.log("error.response : ", error.response)
  //         if(error.response.status === 401){
  //             alert('Your session has expired');
  //             window.location.replace('/signin');
  //         }
  //         if(error.response.status === 429){
  //             console.error('Rate limit hit. Blocking further requests.');
      
  //           // Get the retry-after value (in seconds), fallback to 60 seconds if undefined
  //           const retryAfterHeader = error.response.headers['retry-after'];
  //           const retryAfter = retryAfterHeader ? parseInt(retryAfterHeader, 10) : 60;
      
  //           rateLimitActive = true;
  //     // Trigger the popup in the React component, showing retry timer
  //       if (triggerPopup) {
  //         triggerPopup(retryAfter);
  //       }
  //           if (rateLimitResetTimeout) {
  //             clearTimeout(rateLimitResetTimeout);
  //           }
      
  //           // Set a timer to reset the rate limit flag after the retry delay
  //           rateLimitResetTimeout = setTimeout(() => {
  //             rateLimitActive = false;
  //             console.log('Rate limit window expired. API requests can resume.');
  //           }, retryAfter * 1000);
      
  //           // Retry the request after the specified delay
  //           return retryRequest(error, retryAfter);
  //         }
      
  //         return Promise.reject(error);  // Propagate other errors
  //     });
  // }


export default instance; 