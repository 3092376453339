import React from 'react';
import {
  Button,
  Popup,
  FeedSummary,
  FeedLabel,
  FeedEvent,
  FeedDate,
  FeedContent,
  CardHeader,
  CardContent,
  Card,
  Feed,
  Image,
  Header,
  Divider,
} from 'semantic-ui-react';
import moment from 'moment';
import './TalentPopup.css'; 
import ResumeModal from '../New-TalentCard/BasicDetails/PersonalDetails/ResumeModal/Resume'
import styled from 'styled-components';
import UserImg from "../../images/user.webp";
import Phone from './phone-icon.svg'
import Email from './mail-icon.svg'
import LinkedIn from '../New-TalentCard/BasicDetails/OtherDetails/Images/Linkedin.svg'
import Github from '../New-TalentCard/BasicDetails/OtherDetails/Images/Github.svg'



function TalentPopup({ candidate, url, isPublic }){
    console.log(candidate,"kjhgfghjk")
    
  const  styled={
    borderRadius: "12px",
    border:" 0.10px solid var(--Blue, #003458)",
    background: "#E9F4FF",
    padding: "5px",
    height: "15px",
    padding: "10px",
    lineHeight: "0px",
    color:"#003458",
    marginBottom: "2px",
    fontSize:"14px"

    }
    const styles={
      borderRadius: "21px",
background: "#FFF",
boxShadow: "0px 4px 8px -1px rgba(0, 0, 0, 0.25)",
// width:"450px"
    }

    const DateDisplay = ({ dateString }) => {
      const date = new Date(dateString);
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-GB', options);
    
      return <p style={{color:"var(--grey)", fontSize:"12px"}}>{formattedDate}</p>;
    };

    console.log(url,"sdfaksdhjfadzks")
    function truncateString(str) {
      return str.length > 10 ? str.slice(0, 10) + '...' : str;
    }
    const fullName = `${candidate.first_name || candidate.firstName || 'NA'} ${candidate.last_name || candidate.lastName || 'NA'}`;
    const truncatedFullName = truncateString(fullName);
    return(
  <Popup
    pinned
    // on='click'
    style={styles}
    flowing 
    hoverable
    wide='very'

    content={
      <>
        <CardContent>
          {/* <CardHeader>
        {candidate.first_name || candidate.firstName || 'NA'} {candidate.last_name || candidate.lastName || 'NA'}
        
          </CardHeader>
          <p>
        {candidate?.currentDesignation}
          </p> */}
           <Feed>
            <FeedEvent>
              <FeedLabel style={{width:"70px"}} image={candidate?.profileURL ?  candidate?.profileURL : UserImg} />
              <FeedContent style={{lineHeight:"22px"}}>
              <CardHeader >
                <Header as="h4" style={{}} className='talent-candidatePopup'>

       {candidate.first_name || candidate.firstName || 'NA'} {candidate.last_name || candidate.lastName || 'NA'} 
        {/* <DateDisplay dateString={candidate?.lastUpdated} /> */}
                </Header>
          </CardHeader>
                <FeedSummary style={{marginBottom:'5px', color:"#645B5B", fontWeight:"400"}}>
                {candidate?.currentDesignation}
                </FeedSummary>
              
                {
                  candidate.source ==="talent" ?
                  <Button style={styled} as="a" target="_blank" rel="noopener noreferrer" href={`/talent_pool/candidate/${ candidate._id.includes("@") ?candidate.talent_id  : candidate._id }/bio`}>View Talent Card</Button>
                  
: null      
                }
              </FeedContent>
            </FeedEvent>
          </Feed>
        </CardContent>
       <Divider style={{margin:"0.5rem 0rem 0.8rem 0rem"}} />
        <CardContent style={{padding:"0px 8px"}}>
            {/* <ResumeModal id={candidate?.talent_id} /> */}
            <div style={{display:'flex', gap:'6px'}}>
              {candidate.resumeURL ? 
            <Button style={styled} as="a" target="_blank" rel="noopener noreferrer" href={candidate?.resumeURL}>Resume</Button>
            : null}
           
            {candidate?.workLinks?.linkedinLink  ? <Image src={LinkedIn} className="LinksButton" as='a'
    href={candidate?.workLinks?.linkedinLink}
    target='_blank'/> : null}

             {candidate?.workLinks?.githubLink  ? <Image src={Github} className="LinksButton"
             as='a'
             href={candidate?.workLinks?.githubLink}
             target='_blank'/> : null}
            </div>
            {
              candidate.email ? <div  className='talent-candidatePopup' style={{display:'flex', paddingTop:"5px"}}><Image src={Email} style={{height:"20px", paddingRight:"5px"}} /> {candidate?.email}</div> : null
            } 
            {
              candidate.phone ? <div className='talent-candidatePopup' style={{display:'flex'}}><Image src={Phone} style={{height:"20px", paddingRight:"5px"}}/> {candidate?.phone}</div> : null
            }
            
        </CardContent>
        <Divider  style={{margin:"0.5rem 0rem"}}/>
        <div style={{padding:'0px 8px'}}>
        {
              candidate.createdAt
              ? <div style={{display:'flex', color:"#B3B3B3"}}>Added on: &nbsp; <DateDisplay dateString={candidate?.createdAt} /></div> : null
            }
            {
              candidate.lastUpdated ? <div style={{display:'flex', color:"#B3B3B3"}}>Last Updated: &nbsp;<DateDisplay dateString={candidate?.lastUpdated} /> </div> : null
            }
            </div>
      </>
    }
    trigger={
         <div>
    <Image src={candidate?.profileURL ?  candidate?.profileURL : UserImg} className='table-photo' circular  />
                  <div >
                 {/* <p  style={{display:'flex', marginBottom:'3px', overflowWrap:"anywhere"}}> 
                 <a target="_blank" rel="noopener noreferrer" href={`/talent_pool/candidate/${ candidate._id.includes("@") ?candidate.talent_id  : candidate._id }/bio`} style={{color:"#003458"}}>
                 {fullName}
                 </a>
                  </p> */}
                  {
                    candidate?.lastUpdated && !isPublic ?  
                    <>
                    <span style={{color:"var(--grey)", fontSize:'12px'}}> Last Upd.</span> <br/>
                    <DateDisplay dateString={candidate?.lastUpdated} style={{color:"#979191", fontSize:'13px'}} />
                    </>
                     : null
                  }
         
                  </div>
                  
  </div>
        
    }
  />
);
}
export default TalentPopup;
