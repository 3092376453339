import {
  SET_ALL_TALENT,
  SET_FETCH_CAND_TALENT_POOL,
  SET_JOB_FOR_TALENT_POOL,
  ADD_TALENTPOOL_NOW,
  SET_TALENT_FILTER,
  LOADING_API,
  REQUEST_SUBMIT
} from "./../types.js";

export default function career(
  state = {
    loading: false,
    loadingState: false,
    talents: [],
    candidate: {},
    totalCandidates: [],
    totalNumberOfCandidateFound: 0,
    page: 1,
    filter_options: [],
    updatedfilter: 
      {
        locations: [],
        currentDesignation: "",
        skills: [],
        role: {
          role_type: "",
          subCategory: "",
        },
        tag: [],
        searchByName: "",
        companyName: "",
        addedDate: [],
        qaSt: false,
        curSt: false,
        salary: {
          min: 0,
          max: 0,
        },
        experience: {
          min: 0,
          max: 0,
        },
        minNotice: 0,
        maxNotice: 0,
        isMappedCandidates:false
      },
    multipleCandidateesSelected: [],
    savedSearch: [],
    customPool: {
      isCustomPoolActive: false,
      customPoolId: null
    }
  },
  action = {}
) {
  switch (action.type) {
    case LOADING_API:
      return Object.assign({}, state, { loading: true });
    
    case "LOADING_API_STATE":
      return Object.assign({}, state, { loadingState: true });
  
    case SET_TALENT_FILTER:
      let filter_option = {};

      let locList = [];
      let tag = [];
      let skills = [];
      let RoleList = [];

      if (action.data) {
        console.log(action.data);
        action.data.location.map((data, ind) => {
          let m = { key: ind, value: data, label: data };
          locList.push(m);
        });
      }

      if (action.data) {
        action.data.data[0].searchTag.map((data, ind) => {
          let m = { key: ind, value: data, label: data };
          tag.push(m);
        });
      }

      if (action.data) {
        action.data.data[0].skill.map((data, ind) => {
          let m = { key: ind, value: data, label: data };
          skills.push(m);
        });
      }

      if (action.data) {
        action.data.data[0].designation.map((data, ind) => {
          let m = { key: ind, value: data, label: data };
          RoleList.push(m);
        });
      }

      filter_option.locationOption = locList;
      filter_option.tagOption = tag;
      filter_option.skillOption = skills;
      filter_option.roleOption = RoleList;
      return Object.assign({}, state, {
        filter: action.data,
        filter_options: filter_option,
      });

    case SET_ALL_TALENT:
      console.log("kkkkkkkkkkkkkkkkkkoooooooooooo", action.data);

      // var allskills = [];
      // action.data.skill.forEach((skill, i) => {
      //   // allskills.concat(skill.skills)
      //   allskills = allskills.concat(skill.skills)
      // })      // console.log(allskills)

      return Object.assign({}, state, {
        talents: action.data.talents,
        loading: false,
        totalCandidates: action.data.totalCandidates,
        totalNumberOfCandidateFound: action.data['Number of talents found'],
        filter: state.updatedfilter[0],
      });

    case SET_FETCH_CAND_TALENT_POOL:
      return Object.assign({}, state, { candidate: action.data.talent });

    case SET_JOB_FOR_TALENT_POOL:
      return Object.assign({}, state, { job_titles: action.data });

    case ADD_TALENTPOOL_NOW:
     console.log("adding new talent pool", action.data);
     const newData = {
      ...action.data,
      currentCTC: action.data.currentCTC,
      source:"talent"
     } ;
      return {
        ...state,
        talents: [
            newData,
            ...state.talents
          
        ],
      };



      /////////////// SAVED SEARCH ///////////////////////
      case "GET_SAVED_SEARCH":
        return Object.assign({}, state, { savedSearch: action.data })

      case "CREATE_SAVED_SEARCH":
        return Object.assign({}, state, { savedSearch: [action.data, ...state.savedSearch] });

      case "EDIT_SAVED_SEARCH":
        const modifiedSearch = state.savedSearch.map(savedSearch => savedSearch._id === action.data._id ? action.data : savedSearch);
        return Object.assign({}, state, { savedSearch: modifiedSearch})
      ///////////////// NEW SETTINGS //////////////////
      case "DELETE_SAVED_SEARCH_AND_CUSTOM_SEARCH":
        const updatedData = state.savedSearch.filter(search => search._id !== action.data._id);
        return {
          ...state,
          customPool: {
            isCustomPoolActive: false,
            customPoolId: null
          },
          savedSearch: updatedData
        }
      case 'SET_PAGE':
        return Object.assign({}, state, { page: action.data});

      case 'SET_FILTER_VALUE': 
        let customPool = {
          isCustomPoolActive: false,
          customPoolId: null
        }
        return Object.assign({}, state, { updatedfilter: action.data, customPool: customPool, page: 1, loadingState: false});
      case 'SET_CUSTOM_POOL':
        return Object.assign({}, state, { customPool: action.data, page: 1, loadingState: false});
      case 'REMOVE_IDS_FROM_CUSTOM_POOL':
        let updatedTalent = state.talents.filter(obj => !action.data.includes(obj._id));
         return Object.assign({}, state, { talents: updatedTalent });
    default:
      return state;
  }
}
