import React, { useEffect, useState } from 'react';

export const useModalOpen = () => {
    const [isOpen, setOpen] = useState(false);
    const handleChange = () => {
      console.log("vinod-----",)
      setOpen(!isOpen);
    }
    return {
      isOpen,
      onChange: handleChange
    }
  }

  export const useModal = () => {
    const [ open, setOpen ] = useState(false);
    const [ messagesAndStatus, setMessageAndStatus ] = useState({
        message : '',
        isSuccess : true
    });

    const handleMessageAndStatus = (text, status) => {
        setOpen(true);
        setMessageAndStatus({
            message : text,
            isSuccess : status
        })
    }
    return {
        open,
        messagesAndStatus,
        handleMessageAndStatus,
        handleChange : () => setOpen(!open)
    }
}

export const useDebounce = (value, delay = 500) => {

  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {

    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debouncedValue;
}